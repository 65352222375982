import { useMutation } from '@apollo/client';
import { useForm } from '@slal/ui/forms';
import { attempt, get, isError } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormProps, DefaultValues } from 'react-hook-form';
import { ApplicationFormSubmitHandler, UseApplicationFormReturn } from '~/features';
import { CreateApplication } from '~/graphql';
import {
    AdditionalDetailsSchema,
    ApplicationSchema,
    ApplicationBackRouteKeys,
    ApplicationFormKeys,
    ApplicationPensionValues,
    InvestmentOptionSchema,
    InvestmentPathwayOptionSchema,
    LumpSumOptionsSchema,
    PensionSchema,
    PersonalInformationSchema,
    RegularIncomeSchema,
    WithdrawalOptionsSchema,
    CashLikeFundDeclarationSchema,
    InvestmentPathwayOption,
    LumpSumOptions,
} from '~/types';
import { getPensionValues } from '~/utils';
import { useSelectedInvestmentPathway } from './funds';

export const APPLICATION_KEY = 'APPLICATION';

export const useApplication = <FormValues>() => {
    const setSession = useCallback((values: object) => {
        const applicationSession = sessionStorage.getItem(APPLICATION_KEY);
        const application: ApplicationSchema = applicationSession ? attempt(JSON.parse, applicationSession) : {};

        if (isError(application)) {
            console.error('Error parsing JSON from session storage');
            return;
        }

        const updatedApplication = { ...application, ...values };

        sessionStorage.setItem(APPLICATION_KEY, JSON.stringify(updatedApplication));
    }, []);

    const getSession = useCallback(
        (formKey: ApplicationFormKeys): (ApplicationSchema & DefaultValues<FormValues> & FormValues) | undefined => {
            const applicationSession = sessionStorage.getItem(APPLICATION_KEY);
            const application: ApplicationSchema = applicationSession ? attempt(JSON.parse, applicationSession) : {};

            if (isError(application)) {
                console.error('Error parsing JSON from session storage');
                return undefined;
            }

            const formValues = get(application, formKey);
            if (!formValues) return undefined;

            return {
                [formKey]: formValues,
            } as (ApplicationSchema & DefaultValues<FormValues> & FormValues) | undefined;
        },
        []
    );

    return {
        setSession,
        getSession,
    };
};

export const useApplicationForm = <FormValues extends FieldValues = FieldValues>(
    options: UseFormProps<FormValues>,
    formKey: ApplicationFormKeys
): UseApplicationFormReturn<FormValues> => {
    const { getSession, setSession } = useApplication<FormValues>();

    useEffect(() => {
        const initialValuesFromStorage = getSession(formKey);
        if (!initialValuesFromStorage) {
            setSession(options.defaultValues!);
        }
    }, []);

    const baseFormOptions: UseFormProps<FormValues> = {
        mode: 'onTouched',
        resetOptions: {
            keepDirty: false,
            keepErrors: false,
            keepTouched: true,
        },
    };
    const sessionValues = getSession(formKey) || options.defaultValues;

    const form = useForm({ ...baseFormOptions, ...options, defaultValues: sessionValues });

    const handleSubmit: ApplicationFormSubmitHandler = useCallback(
        onValidCallback => async event => {
            event.preventDefault();
            const valid = await form.trigger();
            const formValues: FormValues = form.getValues();
            setSession(formValues);
            if (valid && onValidCallback) onValidCallback();
        },
        [setSession, form]
    );
    return {
        form,
        handleSubmit,
    };
};

export const useApplicationPensionValues = (): ApplicationPensionValues => {
    const { getSession } = useApplication();

    const withdrawalOptions = getSession(ApplicationFormKeys.WITHDRAWAL_OPTIONS);
    const pensionTransfer = getSession(ApplicationFormKeys.PENSION_TRANSFER);
    const lumpSumOptions = getSession(ApplicationFormKeys.LUMP_SUM_OPTIONS);

    return getPensionValues({ ...pensionTransfer, ...withdrawalOptions, ...lumpSumOptions });
};

export const useApplicationWithdrawalOption = () => {
    const { getSession } = useApplication();
    const application = getSession(ApplicationFormKeys.WITHDRAWAL_OPTIONS);
    if (!application) return undefined;
    const {
        withdrawal: { option },
    } = application as WithdrawalOptionsSchema;
    return option;
};

export const useApplicationInvestmentPathwayFundCode = () => {
    const { getSession } = useApplication();
    const application = getSession(ApplicationFormKeys.INVESTMENT_PATHWAY_OPTIONS);
    if (!application) return undefined;
    const {
        investmentPathway: { fundCode },
    } = application as InvestmentPathwayOptionSchema;
    return fundCode as InvestmentPathwayOption;
};

export const useApplicationInvestmentOption = () => {
    const { getSession } = useApplication();
    const application = getSession(ApplicationFormKeys.INVESTMENT_OPTIONS);
    if (!application) return undefined;
    const {
        investment: { option },
    } = application as InvestmentOptionSchema;
    return option;
};

export const useApplicationSessionBack = (key: ApplicationBackRouteKeys) => {
    const withdrawalOption = useApplicationWithdrawalOption();
    const investmentOption = useApplicationInvestmentOption();

    switch (key) {
        case 'investment-options':
            return withdrawalOption === 'Regular income' || withdrawalOption === 'Both'
                ? '/application/regular-income'
                : '/application/lump-sum-options';

        case 'key-considerations-information':
            return investmentOption === 'custom'
                ? '/application/fund-options'
                : '/application/investment-pathway-options';

        default:
            console.error('Unknown route key:', key);
            return '/';
    }
};

const useApplicationData = <Schema>(key: ApplicationFormKeys): Schema | undefined => {
    const { getSession } = useApplication();
    const application = getSession(key);
    if (!application) return undefined;
    return application as Schema;
};

export const useApplicationPensions = () => {
    const application = useApplicationData<PensionSchema>(ApplicationFormKeys.PENSION_TRANSFER);
    return application?.pensions;
};

export const useApplicationPersonalInformation = () => {
    const application = useApplicationData<PersonalInformationSchema>(ApplicationFormKeys.PERSONAL_INFORMATION);
    return application?.personalInformation;
};

export const useApplicationAdditionalDetails = () => {
    const application = useApplicationData<AdditionalDetailsSchema>(ApplicationFormKeys.ADDITIONAL_DETAILS);
    return application?.additionalDetails;
};

export const useLumpSumOptions = () => {
    const application = useApplicationData<LumpSumOptionsSchema>(ApplicationFormKeys.LUMP_SUM_OPTIONS);
    return application?.lumpSumOptions;
};

export const useRegularIncome = () => {
    const application = useApplicationData<RegularIncomeSchema>(ApplicationFormKeys.REGULAR_INCOME);
    return application?.regularIncome;
};

export const useCashLikeFundDeclaration = () => {
    const application = useApplicationData<CashLikeFundDeclarationSchema>(
        ApplicationFormKeys.CASH_LIKE_FUND_DECLARATION
    );
    return application?.cashLikeFundDeclaration?.consent;
};

const useLumpSumValue = () => {
    const lumpSumOptions = useLumpSumOptions();

    const { maximumTaxFreeCash, halfTaxFreeCash } = useApplicationPensionValues();

    return useMemo(() => {
        switch (lumpSumOptions?.option) {
            case LumpSumOptions.MAXIMUM_TAX_FREE_CASH:
                return maximumTaxFreeCash;
            case LumpSumOptions.HALF_TAX_FREE_CASH:
                return halfTaxFreeCash;
            case LumpSumOptions.SPECIFY:
                return lumpSumOptions.specifiedAmount;
            default:
                return 0;
        }
    }, [lumpSumOptions, maximumTaxFreeCash, halfTaxFreeCash]);
};

export const useInvestmentPathwayCharges = (fundCode: InvestmentPathwayOption) => {
    const { total } = useApplicationPensionValues();
    const { investmentPathway, loading } = useSelectedInvestmentPathway(fundCode);
    const withdrawalOption = useApplicationWithdrawalOption();
    const regularIncome = useRegularIncome();

    const totalExpenseRate = !loading ? investmentPathway?.totalExpenseRate?.toFixed(3) : '';

    const regularIncomeMonthlyValue = Number(regularIncome?.amount);
    const lumpSumValue = Number(useLumpSumValue());

    const calculateRemainingPensionValue = () => {
        let remainingPensionValue = total;

        switch (withdrawalOption) {
            case 'Regular Income':
                remainingPensionValue -= regularIncomeMonthlyValue;
                break;
            case 'Lump sum':
                remainingPensionValue -= lumpSumValue;
                break;
            case 'Both':
                remainingPensionValue -= lumpSumValue + regularIncomeMonthlyValue;
                break;
            default:
                break;
        }

        return remainingPensionValue;
    };

    const remainingPensionValue = calculateRemainingPensionValue();
    const discount = remainingPensionValue < 25000 ? 0.3 : 0.5;

    return {
        discount,
        totalExpenseRate,
        totalCharge: !loading && totalExpenseRate ? (parseFloat(totalExpenseRate) - discount).toFixed(3) : '',
        loading,
    };
};

export const useCommunicationPreferencesModal = () => {
    const { getSession } = useApplication();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = useCallback(() => setIsModalOpen(true), []);
    const closeModal = useCallback(() => setIsModalOpen(false), []);

    const getCommunicationPreferences = () => {
        const application = getSession(ApplicationFormKeys.COMMUNICATION_PREFERENCES);
        return (
            application?.communicationPreferences || {
                email: true,
                phone: true,
                text: true,
            }
        );
    };

    return {
        isModalOpen,
        openModal,
        closeModal,
        getCommunicationPreferences,
    };
};

export const useCreateApplicationMutation = () => {
    const [createApplication, { data, error, loading }] = useMutation(CreateApplication, {
        onError: console.log,
    });

    return {
        createApplication,
        data: data?.createApplication || null,
        error: !!error,
        loading,
    };
};
